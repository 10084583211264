<template>
  <div class="auth-form-wrapper">
    <div class="flex justify-content-center auth-wrapper">
      <form
        @submit.prevent="handleSubmit(!v$.$invalid)"
        class="p-fluid mr-20"
      >
        <div class="card">
          <h4 class="text-center">Profile Information</h4>
          <!-- First name -->
          <div class="field">
            <div class="p-float-label">
              <InputText id="firstName" v-model="v$.form.firstName.$model" :class="{'p-invalid':v$.form.firstName.$invalid && submitted}"/>
              <label for="firstName" :class="{'p-error':v$.form.firstName.$invalid && submitted}">First Name*</label>
            </div>

            <small
              v-if="(v$.form.firstName.$invalid && submitted) || v$.form.firstName.$pending.$response"
              class="p-error">{{ v$.form.firstName.required.$message.replace('Value', 'First Name') }}
            </small>
          </div>

          <!-- Last name -->
          <div class="field">
            <div class="p-float-label">
              <InputText id="lastName" v-model="v$.form.lastName.$model" :class="{'p-invalid':v$.form.lastName.$invalid && submitted}"/>
              <label for="lastName" :class="{'p-error':v$.form.lastName.$invalid && submitted}">Last Name*</label>
            </div>

            <small
              v-if="(v$.form.lastName.$invalid && submitted) || v$.form.lastName.$pending.$response"
              class="p-error">{{ v$.form.lastName.required.$message.replace('Value', 'Last Name') }}
            </small>
          </div>

          <!-- Email -->
          <div class="field">
            <div class="p-float-label p-input-icon-right">
              <i class="pi pi-envelope"/>
              <InputText id="email" v-model="v$.form.email.$model" :class="{'p-invalid':v$.form.email.$invalid && submitted}" aria-describedby="email-error"/>
              <label for="email" :class="{'p-error':v$.form.email.$invalid && submitted}">Email*</label>
            </div>

            <span v-if="v$.form.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.form.email.$errors" :key="index">
                <small class="p-error">{{ error.$message.replace('Value', 'Email') }}</small>
              </span>
            </span>
            <small
              v-else-if="(v$.form.email.$invalid && submitted) || v$.form.email.$pending.$response"
              class="p-error">{{ v$.form.email.required.$message.replace('Value', 'Email') }}
            </small>
          </div>

          <!-- Password -->
          <div class="field">
            <div class="p-float-label">
              <Password id="password" v-model="v$.form.password.$model" :class="{'p-invalid':v$.form.password.$invalid && submitted}" toggleMask>
                <template #header>
                  <h6>Pick a password</h6>
                </template>
                <template #footer="sp">
                  {{ sp.level }}
                  <Divider/>
                  <p class="mt-2">Suggestions</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric</li>
                    <li>Minimum 8 characters</li>
                  </ul>
                </template>
              </Password>
              <label for="password" :class="{'p-error':v$.form.password.$invalid && submitted}">Password*</label>
            </div>

            <span v-if="v$.form.password.$error && submitted">
              <span v-for="(error, index) of v$.form.password.$errors" :key="index">
                <small class="p-error">{{ error.$message.replace('Value', 'Password') }}</small>
              </span>
            </span>
            <small
              v-else-if="(v$.form.password.$invalid && submitted) || v$.form.password.$pending.$response"
              class="p-error">{{ v$.form.password.required.$message.replace('Value', 'Password') }}
            </small>
          </div>

          <!-- Confirm Password -->
          <div class="field">
            <div class="p-float-label">
              <Password id="confirmPassword" v-model="v$.form.confirmPassword.$model" :class="{'p-invalid':v$.form.confirmPassword.$invalid && submitted}" toggleMask />
              <label for="confirmPassword" :class="{'p-error':v$.form.confirmPassword.$invalid && submitted}">Confirm Password*</label>
            </div>

            <small
              v-if="(v$.form.confirmPassword.$invalid && submitted) || v$.form.confirmPassword.$pending.$response"
              class="p-error">{{ v$.form.confirmPassword.sameAsPassword.$message.replace('Value', 'Confirm Password') }}
            </small>
          </div>

          <!-- Phone -->
          <div class="field">
            <div class="p-float-label">
              <InputText id="phone" v-model="v$.form.phone.$model" :class="{'p-invalid':v$.form.phone.$invalid && submitted}"/>
              <label for="phone" :class="{'p-error':v$.form.phone.$invalid && submitted}">Phone*</label>
            </div>

            <small
              v-if="(v$.form.phone.$invalid && submitted) || v$.form.phone.$pending.$response"
              class="p-error">{{ v$.form.phone.required.$message.replace('Value', 'Phone') }}
            </small>
          </div>
        </div>

        <div class="card">
          <h4 class="text-center">Company Information</h4>
          <!-- Language -->
          <div class="field">
            <div class="p-float-label">
              <InputText id="language" v-model="v$.form.language.$model" :class="{'p-invalid':v$.form.language.$invalid && submitted}"/>
              <label for="language" :class="{'p-error':v$.form.language.$invalid && submitted}">Language*</label>
            </div>

            <small
              v-if="(v$.form.language.$invalid && submitted) || v$.form.language.$pending.$response"
              class="p-error">{{ v$.form.language.required.$message.replace('Value', 'Language') }}
            </small>
          </div>

          <!-- Business type -->
          <div class="field">
            <div class="p-float-label">
              <Dropdown id="businessType" :options="businessTypes" optionLabel="name" v-model="selectedBusinessType" />
              <label for="businessType">Type of business*</label>
            </div>

            <small
              v-if="(v$.selectedBusinessType.$invalid && submitted)"
              class="p-error">{{ v$.selectedBusinessType.required.$message.replace('Value', 'Type of business') }}
            </small>
          </div>

          <!-- Business address -->
          <div class="field">
            <div class="p-float-label">
              <InputText id="businessAddress" v-model="v$.form.businessAddress.$model" :class="{'p-invalid':v$.form.businessAddress.$invalid && submitted}" />
              <label for="businessAddress" :class="{'p-error':v$.form.businessAddress.$invalid && submitted}">Business Address*</label>
            </div>

            <small
              v-if="(v$.form.businessAddress.$invalid && submitted) || v$.form.businessAddress.$pending.$response"
              class="p-error">{{ v$.form.businessAddress.required.$message.replace('Value', 'Business Address') }}
            </small>
          </div>

          <!-- Business name -->
          <div class="field">
            <div class="p-float-label">
              <InputText id="businessName" v-model="v$.form.businessName.$model" :class="{'p-invalid':v$.form.businessName.$invalid && submitted}" />
              <label for="businessName" :class="{'p-error':v$.form.businessName.$invalid && submitted}">Business Name*</label>
            </div>

            <small
              v-if="(v$.form.businessName.$invalid && submitted) || v$.form.businessName.$pending.$response"
              class="p-error">{{ v$.form.businessName.required.$message.replace('Value', 'Business Name') }}
            </small>
          </div>

          <!-- Facebook url -->
          <div class="field">
            <div class="p-float-label">
              <InputText id="facebookUrl" v-model="v$.form.facebookUrl.$model" :class="{'p-invalid':v$.form.facebookUrl.$invalid && submitted}" />
              <label for="facebookUrl" :class="{'p-error':v$.form.facebookUrl.$invalid && submitted}">Facebook url</label>
            </div>

            <small
              v-if="(v$.form.facebookUrl.$invalid && submitted) || v$.form.facebookUrl.$pending.$response"
              class="p-error">{{ v$.form.facebookUrl.url.$message.replace('Value', 'Business Name') }}
            </small>
          </div>

          <!-- Instagram url -->
          <div class="field">
            <div class="p-float-label">
              <InputText id="instagramUrl" v-model="v$.form.instagramUrl.$model" :class="{'p-invalid':v$.form.instagramUrl.$invalid && submitted}" />
              <label for="instagramUrl" :class="{'p-error':v$.form.instagramUrl.$invalid && submitted}">Instagram url</label>
            </div>

            <small
              v-if="(v$.form.instagramUrl.$invalid && submitted) || v$.form.instagramUrl.$pending.$response"
              class="p-error">{{ v$.form.instagramUrl.url.$message.replace('Value', 'Instagram url') }}
            </small>
          </div>

          <!-- Linkedin url -->
          <div class="field">
            <div class="p-float-label">
              <InputText id="linkedinUrl" v-model="v$.form.linkedinUrl.$model" :class="{'p-invalid':v$.form.linkedinUrl.$invalid && submitted}" />
              <label for="linkedinUrl" :class="{'p-error':v$.form.linkedinUrl.$invalid && submitted}">Linkedin url</label>
            </div>

            <small
              v-if="(v$.form.linkedinUrl.$invalid && submitted) || v$.form.linkedinUrl.$pending.$response"
              class="p-error">{{ v$.form.linkedinUrl.url.$message.replace('Value', 'Linkedin url') }}
            </small>
          </div>

          <!-- Agreements -->
          <div class="field-checkbox">
            <Checkbox inputId="agreement1" v-model="agreement1" :binary="true" />
            <label for="agreement1" :class="{'p-error':v$.agreement1.$invalid && submitted}">
              I hereby declare that the above information is true & correct to the best of my knowledge and belief.
            </label>
          </div>
          <div class="field-checkbox">
            <Checkbox inputId="agreement2" v-model="agreement2" :binary="true"/>
            <label for="agreement2" :class="{'p-error':v$.agreement2.$invalid && submitted}">
              I understand that delays may happen in services. If a delay may happen the source may not accept cancellations.
            </label>
          </div>
          <div class="field-checkbox">
            <Checkbox inputId="agreement3" v-model="agreement3" :binary="true" />
            <label for="agreement3" :class="{'p-error':v$.agreement3.$invalid && submitted}">
              I understand that there are no refunds for mistake in carrier. I understand to check my imeis before submitting<br> to make sure I am submitting the correct carrier.
            </label>
          </div>
          <div class="field-checkbox">
            <Checkbox inputId="agreement4" v-model="agreement4" :binary="true" />
            <label for="agreement4" :class="{'p-error':v$.agreement4.$invalid && submitted}">
              I understand to read all descriptions carefully before placing an order. Most time frames and other information is<br> in the service description.
            </label>
          </div>
          <div class="field-checkbox">
            <Checkbox inputId="agreement5" v-model="agreement5" :binary="true" />
            <label for="agreement5" :class="{'p-error':v$.agreement5.$invalid && submitted}">
              I understand there is ZERO TOLERANCE FOR FRAUDULENT ACTIVITY.
            </label>
          </div>
          <div class="field-checkbox">
            <Checkbox inputId="agreement6" v-model="agreement6" :binary="true" />
            <label for="agreement6" :class="{'p-error':v$.agreement6.$invalid && submitted}">
              I understand terms and conditions and other information including service descriptions, pricing, and processing<br> times are subject to change at any moment.
            </label>
          </div>
        </div>

        <Button type="submit" label="Sign Up" class="mt-2"/>
        <div class="flex justify-content-center p-3">Already have an account?&nbsp;<a href="" @click.prevent="GoSignIn">Sign In</a></div>
      </form>
    </div>
  </div>
</template>

<script>
import { email, required, sameAs, minLength, url } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {mapActions} from "vuex";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import company from "@/models/Company";

export default {
  setup: () => ({v$: useVuelidate()}),

  data() {
    return {
      selectedBusinessType: null,
      agreement1: false,
      agreement2: false,
      agreement3: false,
      agreement4: false,
      agreement5: false,
      agreement6: false,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        phone: '',
        language: '',
        businessAddress: '',
        businessName: '',
        facebookUrl: '',
        instagramUrl: '',
        linkedinUrl: '',
      },
      submitted: false,
      showMessage: false,
    };
  },

  methods: {
    ...mapActions({
      register: 'auth/registerCompanyAndAdmin'
    }),

    GoSignIn() {
      this.$router.push({ name: 'login' });
    },

    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.form.businessType = this.selectedBusinessType.id;

      this.register(this.form)
        .then(() => {
          this.$toast.add(toastParamBuilder.success('Company registration was successful, please wait for administrator verification!'));
          this.resetForm();
          this.$router.push({ name: 'login' });
        })
        .catch((error) => {
          this.$toast.add(toastParamBuilder.error(error.response?.data?.message));
        });
    },

    resetForm() {
      this.form.firstName = '';
      this.form.lastName = '';
      this.form.email = '';
      this.form.password = '';
      this.form.confirmPassword = '';
      this.form.phone = '';
      this.form.language = '';
      this.form.businessAddress = '';
      this.form.businessName = '';
      this.form.facebookUrl = '';
      this.form.instagramUrl = '';
      this.form.linkedinUrl = '';

      this.agreement1 = false;
      this.agreement2 = false;
      this.agreement3 = false;
      this.agreement4 = false;
      this.agreement5 = false;
      this.agreement6 = false;

      this.submitted = false;
    },
  },

  computed: {
    businessTypes() {
      return company.typesOfBusiness;
    }
  },

  validations() {
    return {
      form: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        password: { required, minLength: minLength(8) },
        confirmPassword: { required, sameAsPassword: sameAs(this.form.password) },
        phone: { required },
        language: { required },
        businessAddress: { required },
        businessName: { required },
        facebookUrl: { url },
        instagramUrl: { url },
        linkedinUrl: { url },
      },
      selectedBusinessType: { required },
      agreement1: { accepted: val => val === true },
      agreement2: { accepted: val => val === true },
      agreement3: { accepted: val => val === true },
      agreement4: { accepted: val => val === true },
      agreement5: { accepted: val => val === true },
      agreement6: { accepted: val => val === true },
    };
  },
}
</script>

<style>
  .mr-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style>
